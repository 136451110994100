<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>续费管理</p>
			<i>/</i>
			<p>续费记录</p>
		</nav>
		
		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="续费工单号:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="请输入续费工单号"></el-input>
						</el-form-item>
						<el-form-item label="创建时间：" label-width="120px">
							<el-date-picker class="input_long" v-model="placeTime"
								value-format="yyyy-MM-dd" type="daterange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="renew_order_sn" label="续费工单号" align="left"></el-table-column>
				<el-table-column prop="create_time" label="下单时间" align="center"></el-table-column>
				<el-table-column prop="agent_name" label="代理商名称" align="center"></el-table-column>
				<el-table-column prop="role_name" label="代理商角色名称" align="center"></el-table-column>
				<el-table-column prop="useful_life" label="时长(年)" align="center"></el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>



	</div>
</template>

<script>
	
	export default {
		name: 'renewRunList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				placeTime: '', // 按创建时间搜索
			}
		},
		components: {
			
		},
		created() {
			// 续费工单列表
			this.getRenewList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.placeTime = '';
				this.getRenewList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getRenewList();
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getRenewList();
			},

			// 续费工单列表
			getRenewList(){
				let start_time = '';
				let end_time = '';
				if(this.placeTime.length > 0){
					start_time = this.placeTime[0];
					end_time = this.placeTime[1];
				}
				
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentreneworder/list`,
					data: {
						limit: 10,
						keyword: this.keyword,
						page: this.currentPage1,
						start_time: start_time,
						end_time: end_time,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			
		}
	}
</script>
<style scoped lang="less">
	.operation {
		span {
			padding: 8px;
			font-size: 14px;
			color: #EE7B1D;
			cursor: pointer;
		}
	}
</style>
